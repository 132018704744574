import React from 'react';
import Card from './Card';
import './App.css';

function App() {
  const cards1to30 = Array.from({ length: 30 }, (_, i) => i + 1);
  const star = ['⭐️'];
  const cards11to19 = Array.from({ length: 9 }, (_, i) => i + 11);
  const cards = [...cards1to30, ...star, ...cards11to19];
  
  const shuffledCards = cards.sort(() => Math.random() - 0.5);

  return (
    <div className="App">
      <h1>STREAMS!</h1>
      <div className="Streams">
        {shuffledCards.map((number, index) => (
          <Card key={index} number={number} />
        ))}
      </div>
      <div className="Footer"><h5>내일의인간 junedevkr</h5></div>
    </div>
  );
}

export default App;
