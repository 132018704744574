import React, { useState } from 'react';
import './Card.css';

const Card = ({ number }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const flipCard = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className={`card ${isFlipped ? 'flipped' : ''}`} onClick={flipCard}>
      <div className="front" style={{backgroundImage: `url(/back_image.jpg)`}}>
        {/* <img alt="Card Back" /> */}
      </div>
      <div className="back">{number}</div>
    </div>
  );
};

export default Card;
